import styles from "@styles/components/index-our-trust/index.module.scss";

export const IndexOurTrust = () => {
    return (
        <section className={`product-container ${styles.our_trust}`}>
            <div className={'our_trust'}>
                <h4>unfiltered honey</h4>
                <h3 className={'desktop'}>purity guarantee</h3>
                <h3 className={'ipad-mobile'}>purity guarantee</h3>
                <p>We put care into crafting raw honey with award-winning flavor. That’s why our strict testing
                   standards ensure our honey is unfiltered as it is straight from the hive. This is our purity guarantee: to
                   provide an unmatched level of care and precision that any honeybee would be proud 
                   of.</p>
            </div>
            <div className={'right_side'}>
                <div className={'container'}>
                    <img src={'/assets/images/decorations/index/process_section_illustration.webp'} alt={'sidr process'}/>
                </div>
            </div>
        </section>
    )
}
