import {IndexAboutUs} from "@components/index-about-us";
import {HeaderImageSlider} from "@components/header-slider/HeaderSlider";
import * as React from "react";
import dynamic from 'next/dynamic'
import {strapi} from "@api/Strapi";
import {IndexMedia} from "@components/media/media-index";
import qs from "qs";
import {IndexOurTrust} from "@components/index-our-trust";


// Loading Component Client Side
const HoneyListSlider = dynamic(() => import('@components/honey-slider/index').then((mod) => mod.HoneyListSlider), {})

// @ts-ignore
function Index({BannerList, Collections, mediaList}) {
    return (
        <>
            {/* Image Slider */}
            <HeaderImageSlider BannerList={BannerList}></HeaderImageSlider>

            {/* Text of Our products */}
            <section className={'our-products'}>
                <h1>sidr raw & unfiltered honey, 100% pure.</h1>
                <p>Through our comprehensive industry-leading standards for honey quality and authenticity and
                    third-party testing measures, we can ensure the high-quality, unadulterated, raw & unfiltered honey
                    ends up in your jar.</p>
            </section>
            {
                Collections.map((collection: any, index: any) => {
                    return <>
                        <HoneyListSlider key={'honey-' + index} Collection={collection}
                                         index={index}></HoneyListSlider>
                        {
                            index == 0 &&
                            <IndexOurTrust key={'honey-' + index}></IndexOurTrust>
                        }
                    </>
                })
            }
            {/* Media */}
            <IndexMedia mediaList={mediaList}></IndexMedia>
            {/* About us section */}
            <IndexAboutUs></IndexAboutUs>
        </>
    )
}

export async function getServerSideProps(context: any) {

    try {
        const propsList: any = {}
        const response = await strapi.getBannerList();

        if (response.status == 200 && response.data) {
            propsList.BannerList = response.data.data;
        }

        const responseCollection = await strapi.getCollectionsWithProductsList();
        if (responseCollection.status == 200 && responseCollection.data && responseCollection.data.data) {

            for (let i = 0; i < responseCollection.data.data.length; i++) {
                for (let j = 0; j < responseCollection.data.data[i].products.length; j++) {
                    delete responseCollection.data.data[i].products[j].SEO_Image;
                    delete responseCollection.data.data[i].products[j].createdBy;
                    delete responseCollection.data.data[i].products[j].updatedBy;
                }
            }
            propsList.Collections = responseCollection.data.data;
        }

        let mediaList: any = []
        let queryObject: any = {
            fields: ["Title", "Link", "Date"],
            pagination: {
                page: 0,
                pageSize: 3
            },
            sort: ['Date:desc','id:desc']
        }
        let query = qs.stringify(queryObject, {encodeValuesOnly: true,});
        try {
            const response = await strapi.getMediaList(query);
            if (response.status == 200 && response.data && response.data.data) {
                mediaList = response.data.data;
            }
        } catch (e) {
        }
        propsList.mediaList = mediaList;
        return {props: propsList}
    } catch (e) {
        console.log(e)
        return {props: {}}
    }
}

export default Index;
