import styles from '@styles/components/index-about-us/index.module.scss';
import {useRef} from "react";
import {useInView} from "framer-motion";
import Link from 'next/link'

export const IndexAboutUs = () => {

    // these variables are used to animate the left and right image when this view are on screen.
    const ref = useRef(null);
    const isInView = useInView(ref, {once: false});

    /**
     * this section contain html code of the about us section in which we are describing about the company.
     * and contain navigation link.
     */
    return (
        <section className={`product-container ${styles.about_us}`} ref={ref}>
            <div className={`${styles.images} ${isInView ? 'image_animation' : ''}`}>
                <img className={styles.desktop} src="/assets/images/decorations/honey_gives_hope/honey_gives_hope_desktop_left.webp"
                     alt="image" loading={"lazy"}/>
                <img className={styles.desktop} src="/assets/images/decorations/honey_gives_hope/honey_gives_hope_desktop_right.webp"
                     alt="image" loading={"lazy"}/>
                <img className={styles.ipad} src="/assets/images/decorations/honey_gives_hope/honey_gives_hope_ipad_left.webp"
                     alt="image" loading={"lazy"}/>
                <img className={styles.ipad} src="/assets/images/decorations/honey_gives_hope/honey_gives_hope_ipad_right.webp"
                     loading={"lazy"}
                     alt="image"/>
                <img className={styles.mobile} src="/assets/images/decorations/honey_gives_hope/honey_gives_hope_mobile_left.webp"
                     loading={"lazy"} alt="image"/>
                <img className={styles.mobile} src="/assets/images/decorations/honey_gives_hope/honey_gives_hope_mobile_right.webp"
                     loading={"lazy"}
                     alt="image"/>
            </div>
            <div className={styles.about_us_container}>
                <div className={`titles ${styles.titles}`}>
                    <strong className={`title-bold ${styles.title_small}`}>unfiltered honey</strong>
                    <h3 className={`title-bold ${styles.title_bold}`}>honey gives hope</h3>
                    <div className={styles.dotted_line}></div>
                </div>
                <span>Adopting limitless innovation to bring about plentiful supply for generations to come. The
                        future of food security tomorrow begins with the seeds of innovation today.</span>
                <Link href={'/impact'} className={styles.btn_learn_more}>
                    know more
                </Link>
            </div>
        </section>
    )
}
