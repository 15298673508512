import * as React from "react";
import styles from '../../styles/components/header-slider/header-slider.module.scss';
import Carousel from "nuka-carousel/lib/carousel";
import Link from "next/link";

// @ts-ignore
export const HeaderImageSlider = ({BannerList}) => {

    /**
     * this section contain the images' slider, used carousel for that, and some css are override to match the design.
     */
    return (<div className={`${styles.header_banner}`}>
        <Carousel wrapAround={true}
                  speed={1000}
                  cellAlign={"center"}
                  style={{overflow: "hidden"}}
                  renderCenterLeftControls={({previousDisabled, previousSlide}) => (
                      <div className={`${styles.btn} ${styles.btn_previous}`} onClick={previousSlide}>
                          <img src='/assets/images/decorations/extra/ic_previous.svg' height={12}
                               width={12}
                               alt={'previous'}/>
                      </div>
                  )}
                  renderCenterRightControls={({nextDisabled, nextSlide}) => (
                      <div className={`${styles.btn} ${styles.btn_next}`} onClick={nextSlide}>
                          <img src='/assets/images/decorations/extra/ic_next.svg' height={12}
                               width={12} alt={'next'}/>
                      </div>
                  )}
                  renderBottomCenterControls={({slideCount, currentSlide, goToSlide}) => (
                      <div className={styles.slider_pagination}>
                          {
                              BannerList.map((_: any, index: number) => {
                                  return (<span key={'dot-' + index} onClick={() => {
                                      goToSlide(index)
                                  }}
                                                className={`${styles.dot} ${index == currentSlide ? styles.active : ''} ${BannerList[currentSlide].attributes.IS_Dark_Image ? styles.dot_dark : ''} ${(index == currentSlide && BannerList[currentSlide].attributes.IS_Dark_Image) ? styles.dot_dark_active : ''}`}/>)
                              })
                          }
                      </div>
                  )}>
            {
                BannerList.map((banner: any, index: number) => {
                    return (<div key={index} className={styles.slider_div}>
                        <img src={banner.attributes.Image.data.attributes.url} width={'100%'}
                             alt={banner.attributes.Image.data.attributes.alternativeText}></img>
                        <div className={styles.div_title_button}>
                            <div>
                                <div className={styles.text_span}
                                     style={{color: banner.attributes.IS_Dark_Image ? '#F9F7F2' : '#1D1F22'}}
                                     dangerouslySetInnerHTML={{__html: banner.attributes.Title}}></div>

                                <Link href={`/${banner.attributes.Link}`} className={styles.button_span}
                                      style={{
                                          color: banner.attributes.IS_Dark_Image ? '#1D1F22' : '#F9F7F2',
                                          backgroundColor: banner.attributes.IS_Dark_Image ? '#F9F7F2' : '#1D1F22'
                                      }}>{banner.attributes.Button_Title}</Link>
                            </div>
                        </div>
                    </div>);
                })
            }
        </Carousel>
    </div>)
}
