import styles from '@styles/components/media/media-index.module.scss';
import Link from "next/link";
import {format, parseISO} from "date-fns";
import * as React from "react";

export const IndexMedia = ({mediaList = []}) => {
    return (<>

            <section className={`product-container ${styles.container}`}>
                {
                    mediaList.length != 0 &&
                    <>
                        <h3>unfiltered honey</h3>
                        <p>know more</p>
                        <h3 className={'about-honey'}>about honey</h3>
                        <div className={'latest_media'}>
                            <hr/>
                            <h3>latest media</h3>
                            <hr/>
                        </div>
                        <div className={'media_release_container'}>
                            {
                                mediaList.map((item: any, index: number) => {
                                    return <div key={'news-' + index} className={'media_item'}>
                                        <Link href={`/media/${item.attributes.Link}`}>
                                            <p>{item.attributes.Title}</p>
                                            <span
                                                className={'date'}>{format(parseISO(item.attributes.Date), 'LLL d, yyyy')}</span>
                                        </Link>
                                    </div>
                                })
                            }
                        </div>
                    </>
                }
                <hr/>
                <div className={'natural_honey'}>
                    <div className={'title'}>
                        {
                            mediaList.length != 0 ? <h2>ingredients: honey</h2> : <h3>ingredients: honey</h3>
                        }
                        <span className={'desktop'}>we test to ensure our quality</span>
                        <span className={'mobile'}>we test to ensure <br/> our quality</span>
                        <span>standards are met for</span>
                    </div>
                    <div className={'table'}>
                        <hr/>
                        <div>
                            <span>Authenticity Analysis</span>
                            <span>Color</span>
                        </div>
                        <hr/>
                        <div className={'even'}>
                            <span>Pesticides</span>
                            <span>Pollen and Botanical Analysis</span>
                        </div>
                        <hr/>
                        <div>
                            <span>Antibiotics</span>
                            <span>Moisture</span>
                        </div>
                        <hr/>
                        <div className={'even'}>
                            <span>Sugar Spectrum</span>
                            <span>Organoleptic Analysis</span>
                        </div>
                        <hr/>
                    </div>
                </div>
                <hr/>
            </section>
        </>
    )
}
